.gestao-select-cont {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}
.gestao-select {
	border-radius: 5px;
	padding: 10px;
	margin: 5px 20px;
}
.gestao-select-text {
	text-transform: uppercase;
	font-weight: 500;
}
.gestao-select-button {
	padding: 10px 20px;
	margin: 0 10px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	border-radius: 6px;
}
.gestao-select-button:focus {
	background: #5a5a5a;
	color: #ffffff;
}
.gestao-sku {
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 80px;
	text-align: center;
}
.gestao-ean {
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-width: 120px;
	padding-left: 10px;
}
.gestao-table-image {
	width: 50px !important;
	height: 50px !important;
	background: #e2e2e2;
}
.gestao-image {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 100px;
	text-align: center;
}
.gestao-image-link {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-width: 30px;
}
.gestao-title {
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 450px;
	padding-left: 10px;
}
.gestao-total {
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 150px;
	flex: 0 8%;
}
.gestao-sticky-block {
	display: flex;
	flex-direction: row;
	/* width: 555px; */
	height: 100%;
	align-items: center;
	position: sticky;
	left: 0;
	background-color: #fff;
	z-index: 100;
}
.title-border {
	border-radius: 10px 0 0 0;
}
.gestao-vinuus {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	flex: 0 1 6%;
	position: relative;
	min-width: 150px;
	border: 1px solid #fff;
	height: 99%;
}
.gestao-vinuus:hover {
	border: 1px solid #af1b3f;
}
.gestao-cost,
.gestao-stock {
	display: flex;
	height: 100%;
	align-items: center;
	padding-left: 5px;
	border-top: 1px solid #e1e1e1;
}
.gestao-cost {
	width: 60%;
	justify-content: flex-end;
	padding-right: 5px;
}
.gestao-stock {
	width: 40%;
	justify-content: center;
}
.gestao-sku-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #5a5a5a;
}
.gestao-ean-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #5a5a5a;
}
.gestao-title-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}
.gestao-total-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 23px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #5a5a5a;
}
.gestao-cost-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}
.gestao-stock-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}
.gestao-cost-update {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #b4b4b4;
	border-bottom: 1px solid #b4b4b4;
	cursor: pointer;
}
.gestao-cost-update:hover {
	color: #af1b3f;
}
.gestao-active {
	background: #5a5a5a;
	color: #ffffff;
}
.gestao-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.refresh-image {
	opacity: 0.7;
	margin-right: 10px;
}
.refresh-image:hover {
	cursor: pointer;
	opacity: 0.9;
	transform: scale(1.05);
}
.sticky-header-gestao {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 70px;
	position: sticky !important;
	top: 10px;
}
.gestao-results-scroll {
	height: 550px !important;
	overflow: scroll;
}
.modal .pointer .info-button {
	opacity: 0.4;
}
.MuiTooltip-popper .pricingTooltop {
	color: #666;
	background-color: #fff;
	margin: -4px -8px;
	padding: 15px;
	border: 1px solid #6667;
	box-shadow: 1px 1px 2px 0px #6667;
	font-size: 1.4em;
	font-weight: 400;
}
.MuiTooltip-popper .pricingTooltop .service {
	margin-bottom: 15px;
	display: flex;
	justify-content: space-between;
	font-weight: bold;
	text-transform: uppercase;
}
.MuiTooltip-popper .pricingTooltop li {
	line-height: 1.4em;
	font-size: 0.95em;
}

.MuiTooltip-popper .pricingTooltop li::before {
	content: "\2713";
	color: green;
	margin-right: 5px;
	font-weight: bold;
}
.MuiTooltip-popper .pricingTooltop .margin {
	margin-top: 15px;
	padding-top: 15px;
	display: flex;
	justify-content: space-between;
	font-weight: bold;
	text-transform: uppercase;
	border-top: 1px dotted #999;
}
.modal .priceBreakdown {
	min-width: 590px;
}
@media screen and (max-width: 991px) {
	.gestao-sticky-block {
		position: initial;
	}
	.gestao-select-cont {
		flex-direction: column;
	}
	.gestao-select-button {
		margin-bottom: 20px;
	}
	.gestao-select-text {
		margin-bottom: 10px;
	}
	.gestao-select-button:last-child {
		margin-bottom: 0px;
	}
}
@media screen and (max-width: 767px) {
	.gestao-sku-text {
		margin: 10px 0;
	}
	.gestao-ean-text {
		margin: 10px 0;
	}
	.gestao-title-text {
		margin: 10px;
	}
	.gestao-total-text {
		margin: 10px 0;
	}
	.gestao-box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin: 10px 0;
	}
}

.table-title-cont {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
	background-color: #e2e2e2;
	padding: 20px 40px;
	width: 100%;
}
.table-subtext {
	font-size: 14px;
	line-height: 16px;
	color: #ACACAC;
}
.table-title-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 35px;
	text-align: center;
	color: #5a5a5a;
}
.table-title-sort {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}
.table-main-cont {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background: #f2f2f2;
	min-height: 98vh;
	width: 100%;
}
.table-value-search-cont {
	display: flex;
	width: 100%;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-right: 20px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.table-value-search-area {
	display: flex;
	width: 100%;
	padding-top: 20px;
	padding-bottom: 20px;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.table-border-bottom {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 97%;
	border-bottom: 1px solid #bfbfbf;
}
.table-value {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 25px;
	text-transform: uppercase;
	color: #5a5a5a;
	padding-left: 40px;
	margin-right: 20px;
}
.table-results {
	padding-top: 30px;
	position: relative;
	width: 100%;
	min-height: 500px;
	padding-left: 0;
	padding: 0 40px;
}
.table-results-title {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background-color: #af1b3f;
	text-align: center;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff !important;
	height: 70px;
	border: 1px solid #BFBFBF;
	box-sizing: border-box;
	border-radius: 10px 10px 0 0 !important;
	position: relative;
}
.table-result-box {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	background-color: #fff;
	border-bottom: 1px solid #e1e1e1;
	height: 60px;
	border-left: 1px solid #BFBFBF;
	border-right: 1px solid #BFBFBF;
	border-bottom: 1px solid #BFBFBF;
	box-sizing: border-box;
	margin-bottom: 1px;
}
.table-result-box div {
	border-top: none;
}
.table-overflow {
	position: relative;
	display: block;
	overflow: auto;
	width: 100%;
	margin: 10px auto;
	max-width: 100vw !important;
	border-radius: 10px;
	height: 77vh;
	overflow-y: scroll;
}
.mapping-modal-table {
    width: 100%;
}
.mapping-modal-results-title {
    border-bottom: 2px solid #e1e1e1;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.table-pagination {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	padding-bottom: 20px;
}
.table-pagination-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	margin-right: 20px;
}
.table-pagination + p {
	background: #af1b3f;
}
.table-result-box:nth-of-type(even) {
	background-color: #f2f2f2;
}
.table-result-box:last-child {
	border-radius: 0 0 10px 10px;
	margin-bottom: 10px;
}
.table-search-form {
	width: 300px;
	height: 40px;
	background: #ffffff;
	border: 1px solid #acacac;
	box-sizing: border-box;
	border-radius: 20px;
	margin-right: 40px;
	display: flex;
}
.table-search-input {
	width: 80%;
	height: 30px;
	margin: auto;
	background: #ffffff;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: #acacac;
}
.table-search-input:focus {
	border: none;
}
.table-search-image {
	margin-right: 10px;
}
.table-border-left {
	border-left: 1px solid #e1e1e1;
	height: 100%;
}
.table-modal-cont {
	display: flex;
	margin-right: auto;
	margin-left: auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 10px;
}
.table-modal-title-box {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.table-modal-form-title-stock {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 30px;
	color: #5a5a5a;
}
.table-modal-form-box-stock {
	display: flex;
	flex-direction: column;
	margin-top: -20px;
	align-items: flex-end;
	margin-right: 20px;
}
.table-modal-title-pricing {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 25px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #000000;
}
.table-modal-title-title {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 25px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #5a5a5a;
	margin-top: 5px;
	padding-right: 5px;
}
.table-modal-form-cont {
	box-sizing: border-box;
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: right;
	color: #5a5a5a;
	width: 100%;
}
.table-modal-form-columns {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	align-content: flex-start;
	width: 100%;
}
.table-modal-form-main-column {
	width: 100%;
}
.table-modal-border {
	width: 100%;
	height: 0px;
	border: 0.5px solid #e1e1e1;
	margin: 20px 0;
}
.table-modal-form-box {
	display: flex;
	/* margin-right: 100px; */
	flex-direction: row;
	/* justify-content: flex-end; */
	justify-content: center;
	align-items: center;
	margin-bottom: 5px;
}
.table-modal-form-title {
	padding-left: 10px;
	margin-right: 20px;
	text-align: right;
	width: 100%;
	max-width: 240px;
}
.table-modal-form-value {
	width: 80px;
	height: 36px;
	margin-bottom: 0px;
	text-align: center;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 40px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.table-modal-form-difference {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 40px;
	color: #acacac;
	text-transform: lowercase;
	padding-left: 10px;
}
/* .remover {
	display: none !important;
}
.adicionar {
	display: block;
}
.table-modal-form-accordion-title:focus .adicionar {
	display: none;
}
.table-modal-form-accordion-title:focus .remover {
	display: inline !important;
} */
.table-modal-form-green {
	width: 100%;
	background: #ddefdd;
	padding: 10px 0;
	margin-top: 10px;
}
.table-modal-form-red {
	background: #f3dfd4;
	padding: 10px 0;
}
.table-modal-form-button-box {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin-bottom: 40px;
}
.table-modal-form-button {
	background: #af1b3f;
	border-radius: 6px;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	text-transform: uppercase;
	color: #ffffff;
	padding: 10px 60px;
}
.table-modal-form-button-cancel {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	text-decoration-line: underline;
	text-transform: uppercase;
	color: #af1b3f;
	background-color: #fff;
	border: none;
	box-shadow: none;
}
.table-modal-difference {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background: #ffffff;
	border: 1px solid #cccccc;
	box-sizing: border-box;
	border-radius: 10px;
	padding: 20px 40px;
	width: 70%;
	filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.25));
	margin-left: 20px;
}
.table-modal-form-accordion-cont {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 5px;
}
.table-modal-form-accordion-box {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}
.table-modal-desconto-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 40px;
	text-align: right;
	color: #af1b3f;
	padding: 0 5px;
}
.table-modal-form-box-pvp {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 5px 0;
	width: 100%;
}
.table-modal-form-accordion-title {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-decoration-line: underline;
	color: #af1b3f;
}
.table-modal-difference-title {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 40px;
	color: #5a5a5a;
}
.table-modal-difference-cont {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: space-around;
	justify-content: space-between;
}
.table-modal-difference-box {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex: 0 95%;
}
.table-modal-difference-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 40px;
	color: #5a5a5a;
	display: flex;
	flex-direction: row;
}
.table-modal-difference-text-price {
	color: #af1b3f;
	padding-left: 5px;
	text-decoration: underline;
}
.table-modal-border-value {
	background: #f8f8f8;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 6px;
}
.table-border-right {
	border: 1px solid #e1e1e1;
	height: 100%;
}
.newPriceButton {
	width: 50%;
	justify-content: end;
    padding-right: 10px;
	white-space: nowrap;
}
.newPrice {
	width: 35%;
	justify-content: end;
}
.newPrice.off, .dif.off {
	visibility: hidden;
}
.newPrice div.table-modal-form-value {
	margin-right: 20px;
}
.newPrice div.table-modal-form-value.removed {
	text-decoration: line-through;
}
.newPrice div.table-modal-form-value input {
	margin-right: -10px;
}
.modal .width50.on {
	opacity: .5;
}
.table-modal-button-newprice {
	box-shadow: none;
	border: none;
	color: #af1b3f;
	text-transform: unset;
	font-weight: bold;
	background: unset;
	text-decoration: underline;
	white-space: nowrap;
}
.table-modal-form-box.remove {
	justify-content: flex-end;
	padding-right: 10px;
}
.table-modal-form-button-box button:disabled {
	opacity: 0.5;
	cursor: default;
}
.table-modal-form-box .dif {
	white-space: nowrap;
    margin-right: 5px;
	opacity: 0.5;
	width: 24%;
	font-style: italic;
}
.table-modal-form-box .na {
	padding-right: 14px;
}
.table-modal-form-box .esp {
	visibility: hidden;
}
.table-modal-form-box.suggest {
	padding: 10px 0;
}
.modal .proposeON {
	display: none;
}
@media screen and (max-width: 1200px) {
	.table-title-sort {
		align-items: flex-start;
		flex-direction: column-reverse;
		width: 100%;
	}
	.acompan-calendar {
		margin-top: 10px;
	}
}
@media screen and (max-width: 991px) {
	.table-value-search-area {
		flex-direction: column;
	}
	.table-value-search-area div {
		margin: 10px;
	}
}
@media screen and (max-width: 767px) {
	.table-modal-form-columns {
		flex-direction: column;
		align-items: center;
		align-content: center;
	}
	.table-title-cont {
		height: auto;
	}
	.table-title-text {
		margin: 20px 0;
	}
	.table-value-search-cont {
		flex-direction: column;
		padding-right: 0;
	}
	.table-value-search-cont div {
		margin: 10px 0;
	}
	.table-value {
		padding-left: 0;
		margin-bottom: 10px;
	}
	.table-search-form {
		margin-right: 0;
	}
	.table-modal-cont {
		width: 100%;
		overflow: overlay;
		height: 700px;
		padding: 20px 0;
	}
	.table-modal-border {
		margin: 5px 0 20px 0;
	}
	.table-modal-form-box {
		margin-bottom: 5px;
		justify-content: center;
	}
	.table-modal-form-button-box {
		margin-bottom: 10px;
	}
	.table-modal-difference {
		align-items: center;
		width: 100%;
		margin-left: 0;
	}
	.table-modal-difference-box {
		justify-content: center;
		align-items: center;
		flex: 0 1 100%;
	}
	.table-title-sort {
		align-items: center;
	}
}
@media screen and (max-width: 479px) {
	.acompan-dates {
		display: flex !important;
		flex-direction: column !important;
	}
	.acompan-calendar {
		margin-top: 10px;
		margin-bottom: 5px;
	}
	.table-modal-border {
		width: auto;
	}
}

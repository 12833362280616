.single-billing-nav {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding-left: 40px;
	padding-top: 40px;
}
.single-billing-medium {
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 0 12%;
	width: 130px;
	height: 100%;
}
.single-billing-large {
	padding-right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 0 30%;
	width: 300px;
	height: 100%;
}
.single-billing-small {
	padding-right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 0 10%;
	width: 100px;
	height: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
	--white-color: #ffffff;
	--black-color: #000000;
	--orange-color: #ff7a00;
	--blue-color: #1191d9;
	--dark-gray-color: #444444;
	--pale-gray-color: #c4c4c4;
	--light-gray-color: #f2f2f2;
	--mid-gray-color: #898989;
	--base-width: 1040px;
	--header-height: 80px;
}

body {
	z-index: 1;
}

* {
	margin: 0px;
	padding: 0px;
	font-family: "Roboto", sans-serif;
}

a {
	text-decoration: none;
	color: inherit;
}

.primary-color {
	color: #5a5a5a;
}

ul {
	list-style: none;
}

input,
textarea {
	background-color: var(--light-gray-color);
	border-radius: 3px;
	border: none;
}
.input-control {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 8px;
	width: 100%;
}
.input-control > label {
	font-size: 14px;
	margin-bottom: 4px;
	font-weight: 400;
}
.input-control > input,
.input-control > textarea {
	font-weight: 300;
	padding: 8px;
	font-size: 14px;
	color: var(--dark-gray-color);
}
.index1 {
	z-index: 1 !important;
}
.no-color-button,
button {
	text-transform: uppercase;
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4px 16px;
	font-weight: 400;
	font-size: 14px;
}
button {
	box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	color: var(--white-color);
}
.no-color-button {
	color: var(--dark-gray-color);
}
button:hover,
.no-color-button:hover {
	cursor: pointer;
}
.italic-button {
	box-shadow: none;
	color: var(--dark-gray-color);
	padding: 0px;
	background-color: initial;
	text-transform: lowercase;
	font-style: italic;
	text-decoration: underline;
}
.skeleton-button {
	width: 68px;
	height: 18px;
	display: flex;
	border-radius: 10px;
	background-color: var(--light-gray-color);
}
.skeleton-heading {
	width: 240px;
	height: 22px;
	display: flex;
	border-radius: 10px;
	background-color: var(--light-gray-color);
}
/* margins */
.ml0 {
	margin-left: 4px;
}

.ml1 {
	margin-left: 8px;
}

.ml2 {
	margin-left: 12px;
}

.ml3 {
	margin-left: 16px;
}

.ml4 {
	margin-left: 20px;
}

.ml5 {
	margin-left: 24px;
}

.ml6 {
	margin-left: 28px;
}

.mb0 {
	margin-bottom: 4px;
}

.mb1 {
	margin-bottom: 8px;
}

.mb2 {
	margin-bottom: 12px;
}

.mb3 {
	margin-bottom: 16px;
}

.mb4 {
	margin-bottom: 20px;
}

.mb5 {
	margin-bottom: 24px;
}

.mb6 {
	margin-bottom: 28px;
}

.mr0 {
	margin-right: 4px;
}

.mr1 {
	margin-right: 8px;
}

.mr2 {
	margin-right: 12px;
}

.mr3 {
	margin-right: 16px;
}

.mr4 {
	margin-right: 20px;
}

.mr5 {
	margin-right: 24px;
}

.mr6 {
	margin-right: 28px;
}

.mr0 {
	margin-right: 4px;
}

.mt1 {
	margin-top: 8px;
}

.mt2 {
	margin-top: 12px;
}

.mt3 {
	margin-top: 16px;
}

.mt4 {
	margin-top: 20px;
}

.mt5 {
	margin-top: 24px;
}

.mt6 {
	margin-top: 28px;
}
.mn {
	margin: 0 !important;
}
/* paddings */
.pn {
	padding: 0 !important;
}
.pl0 {
	padding-left: 4px;
}

.pl1 {
	padding-left: 8px;
}

.pl2 {
	padding-left: 12px;
}

.pl3 {
	padding-left: 16px;
}

.pl4 {
	padding-left: 20px;
}

.pl5 {
	padding-left: 24px;
}

.pl6 {
	padding-left: 28px;
}

.pb0 {
	padding-bottom: 4px;
}

.pb1 {
	padding-bottom: 8px;
}

.pb2 {
	padding-bottom: 12px;
}

.pb3 {
	padding-bottom: 16px;
}

.pb4 {
	padding-bottom: 20px;
}

.pb5 {
	padding-bottom: 24px;
}

.pb6 {
	padding-bottom: 28px;
}

.pr0 {
	padding-right: 4px;
}

.pr1 {
	padding-right: 8px;
}

.pr2 {
	padding-right: 12px;
}

.pr3 {
	padding-right: 16px;
}

.pr4 {
	padding-right: 20px;
}

.pr5 {
	padding-right: 24px;
}

.pr6 {
	padding-right: 28px;
}

.pr0 {
	padding-right: 4px;
}

.pt1 {
	padding-top: 8px;
}

.pt2 {
	padding-top: 12px;
}

.pt3 {
	padding-top: 16px;
}

.pt4 {
	padding-top: 20px;
}

.pt5 {
	padding-top: 24px;
}

.pt6 {
	padding-top: 28px;
}

.in-row,
.in-column,
.align-center,
.justify-center,
.justify-end,
.align-start,
.justify-start,
.align-end {
	display: flex;
}
.align-start {
	align-items: start;
}
.align-end {
	align-items: flex-end;
}
.justify-start {
	justify-content: flex-start !important;
}
.justify-evenly {
	justify-content: space-evenly !important;
}
.justify-around {
	justify-content: space-around !important;
}
.initial {
	text-transform: initial !important;
}
.height100 {
	height: 100% !important;
}
.height2 {
	height: 2px !important;
}
.height24 {
	height: 24px !important;
}
.height30 {
	height: 30px !important;
}
.mheighti {
	min-height: initial !important;
}
.border-bottom {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	border-bottom: 2px solid #e1e1e1;
}
.text-start {
	text-align: start !important;
}
.red {
	color: #af1b3f !important;
}
.redbg {
	background-color: #af1b3f !important;
}
.grey {
	color: #acacac !important;
}
.grey2 {
	color: #9c9c9c !important;
}
.green {
	color: #48aa6a !important;
}
.greenbg {
	background: #48aa6a !important;
}
.semigreenbg {
	background: #c2e3d1 !important;
}
.blue {
	color: #569bc1 !important;
}
.bluebg {
	background: #569bc1 !important;
}
.semibluebg {
	background: #c4c4c4 !important;
}
.justify-between {
	justify-content: space-between;
}
.in-row {
	flex-direction: row;
}

.in-column {
	flex-direction: column;
}
.p0 {
	padding: 0 !important;
}
.m0 {
	margin: 0 !important;
}
.align-center {
	align-items: center !important;
}
.greybg {
	background: #eeeeee !important;
}
.whitebg {
	background: #ffffff !important;
}
.transperentbg {
	background: transparent !important;
}
.weight500 {
	font-weight: 500;
}
.justify-center {
	justify-content: center;
}

.justify-end {
	justify-content: flex-end;
}
.width100 {
	width: 100%;
}
.width90 {
	width: 90%;
}
.width60 {
	width: 60%;
}
.width50 {
	width: 50%;
}
.width40 {
	width: 40%;
}
.width18 {
	width: 18%;
}
.mwidth120 {
	min-width: 120px !important;
}
.mwidth150 {
	min-width: 150px !important;
}
.mwidth180 {
	min-width: 180px !important;
}
.mwidth200 {
	min-width: 200px !important;
}
.mwidth220 {
	min-width: 220px !important;
}
.mwidth250 {
	min-width: 250px !important;
}
.pointer {
	cursor: pointer;
}
.maxwidth150 {
	max-width: 150px !important;
}
.maxwidth200 {
	max-width: 200px !important;
}
.not-allowed {
	cursor: not-allowed;
}
.center-loader div {
	top: 0;
}

.opacity-5 {
	opacity: 0.2;
}
.opacity-full {
	opacity: 1;
}

.tooltip-container {
	position: relative;
	display: flex;
}
.minheight100 {
	min-height: 100px !important;
}
.minheight150 {
	min-height: 150px !important;
}

.tooltip-container .tooltip-content {
	width: 200px;
	height: 165px;
	top: 110%;
	left: 50%;
	margin-left: -100px;
	visibility: hidden;
	color: var(--dark-gray-color);
	font-weight: 150;
	background-color: var(--white-color);
	text-align: center;
	border-radius: 3px;
	position: absolute;
	z-index: -1;
	opacity: 0;
	box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15);
	-webkit-transition: 0.8s;
	transition: 0.8s;
}

.tooltip-container:hover .tooltip-content {
	visibility: visible;
	z-index: 1000;
	opacity: 1;
}

.italic {
	font-style: italic;
}

.ft0 {
	font-size: 10px;
}

.ft1 {
	font-size: 12px;
}

.ft2 {
	font-size: 14px;
}

.ft3 {
	font-size: 18px;
}

.ft4 {
	font-size: 22px;
}

.ft5 {
	font-size: 26px;
}

.ft6 {
	font-size: 30px;
}

.fw0 {
	font-weight: 200;
}
.fw1 {
	font-weight: 300;
}
.fw2 {
	font-weight: 400;
}
.fw3 {
	font-weight: 500;
}
.fw4 {
	font-weight: 600;
}
.fw5 {
	font-weight: 700;
}
.fw6 {
	font-weight: 800;
}
.pdtop {
	padding-top: 60px;
}

.blue-color {
	color: var(--blue-color);
}

.orange-color {
	color: var(--orange-color);
}

.white-color {
	color: var(--white-color);
}

.dark-gray-color {
	color: var(--dark-gray-color);
}

.light-gray-color {
	color: var(--light-gray-color);
}

.mid-gray-color {
	color: var(--mid-gray-color);
}

.mid-pale-color {
	color: var(--mid-pale-color);
}

.hover-underline:hover {
	text-decoration: underline;
}

.content-wrapper {
	padding-top: var(--header-height);
}
.white-color {
	color: #ffffff !important;
}
.fadein {
	margin-top: 25px;
	font-size: 21px;
	text-align: center;

	-webkit-animation: fadein 0.7s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 0.7s; /* Firefox < 16 */
	-ms-animation: fadein 0.7s; /* Internet Explorer */
	-o-animation: fadein 0.7s; /* Opera < 12.1 */
	animation: fadein 0.7s;
}
.underline {
	text-decoration: underline !important;
}
.overflow {
	overflow: auto;
}
.main-header-cont {
	position: fixed;
	display: flex;
	height: 60px;
	width: 100%;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding-top: 0px;
	z-index: 10;
	background: #ffffff;
	box-shadow: 0 0 10px 4px rgb(0 0 0 / 12%);
}
.pointer {
	cursor: pointer;
}
.main-body {
	height: auto;
	position: relative;
	height: 100%;
}
.main-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-top: -5px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #e8edf2;
}
.review-margin {
	padding-top: 50px;
}
.no-shadow {
	box-shadow: none !important;
}
.vinuus-portal-image {
	margin-right: -10px;
	margin-left: 10px;
}
/* .settings-box-element {
    z-index: 10;
}
   */
.buttons-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 240px;
	height: 100%;
}
/* .settings-btnarrow:checked + label {
      display: none;
  }
  .settings-btnarrow:checked ~ .buttons-wrapper {
    left: -240px;
  }
  .settings-btnmain + label {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    cursor: pointer;
    z-index: 11;
    display: none;
  }
  .settings-btnmain:checked + label {
    display: none;
  }

  .settings-btnmain:checked ~ .buttons-wrapper {
    left: 0;
  }

  .settings-btnarrow:checked ~ .settings-btnmain + label {
    display: block;
  }

  .settings-btnmain:checked ~ .settings-btnarrow + label {
    display: block;
  } */

.width200px {
	width: 200px;
}
@media screen and (max-width: 991px) {
	.main-container {
		margin-left: 0;
	}
	.settings-btnarrow + label {
		position: fixed;
		top: 10px;
		left: 220px;
		display: block;
		cursor: pointer;
		z-index: 11;
	}
}
@media screen and (max-width: 769px) {
	.hide7 {
		display: none;
	}
}
@media screen and (max-width: 496px) {
	.search-box {
		margin-left: 10px;
	}
}
.bold {
	font-weight: bold;
}
.titel_box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}
.vendor-portal {
	padding-right: 10px;
	padding-left: 10px;
	font-family: Helvetica Neue LT Std;
	font-style: normal;
	font-weight: normal;
	font-size: 27px;
	line-height: 32px;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: lowercase;
	color: #ffffff;
	padding-top: 10px;
}
.margin-left5 {
	margin-left: 5px;
}
@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.full-screen {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.blink-me {
	animation: blinker 1s linear infinite;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	select:focus,
	textarea:focus,
	input:focus {
		font-size: 16px !important;
		background: #eee;
	}
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	select,
	textarea,
	input {
		font-size: 16px !important;
	}
}

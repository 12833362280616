.menu-item {
	margin-top: 10px;
	margin-bottom: 10px;
	min-height: 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.notification-title {
	margin: 0 0 10px 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;	
	color: #5A5A5A;
}
.menu-item-active {
	background-color: rgb(255, 255, 255);
}
.notification-image-box {
	width: 50px;
	height: 50px;
	background: #F2EDDC;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.menu-item-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
}

.note-title {
	font-size: 14px;
	line-height: 16px;
	color: #5A5A5A;
	padding: 5px;
	margin-right: 60px;
}

.note-date {
	font-size: 11px;
	line-height: 13px;
	color: #ACACAC;
	padding: 5px;
}

.menu-item-button {
	margin-top: 0;
	margin-bottom: 0;
	height: 36px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	background-color: rgb(255, 255, 255);
	border-top: 1px solid #E1E1E1;
}

.menu-item-button-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 16px;
	margin-top: 10px;
	margin-bottom: 5px;
}

.read-all-button {
	background-color: #920023;
	color: #fff;
	font-size: 12px;
	border-radius: 5px;
}

.read-all-button:hover {
	cursor: pointer;
	transform: scale(1.02);
}
.notification-border {
	border-top: 1px solid #E1E1E1;
}

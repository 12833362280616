.billing-medium {
	padding-right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 0 15%;
	width: 150px;
	height: 100%;
}
.billing-small {
	padding-right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 0 9%;
	width: 100px;
	height: 100%;
}
.billing-text {
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
}
.billing-redtext {
	color: #ed0000 !important;
}
.billing-underline {
	text-decoration: underline !important;
	text-decoration-color: #ed0000 !important;
}
.billing-largetext {
	font-size: 18px;
	line-height: 21px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #5a5a5a;
}
.billing-modal-cont {
	max-height: 700px;
	width: 740px;
	overflow-y: auto;
	padding: 10px;
}
.billing-modal-title-cont {
	padding-top: 10px;
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.billing-modal-text {
	font-size: 16px;
	line-height: 30px;
	color: #5a5a5a;
	text-transform: initial;
}
.billing-modal-subtext {
	text-transform: lowercase;
	padding-left: 10px;
	color: #acacac;
}
.billing-modal-box {
	position: relative;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.billing-input-box {
	width: 100%;
	display: flex;
	text-align: left;
	padding-left: 10px;
	flex-direction: row;
}
.billing-modal-invoice-cont {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.billing-modal-invoice-box {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}
.billing-modal-medium {
	display: flex;
	align-items: center;
	flex: 0 12%;
	max-width: 100px;
	height: 100%;
	padding-right: 10px;
	font-size: 14px;
	line-height: 30px;
}
.billing-modal-xmedium {
	display: flex;
	align-items: center;
	flex: 0 20%;
	max-width: 150px;
	height: 100%;
	padding-right: 10px;
	font-size: 14px;
	line-height: 30px;
}
.billing-modal-large {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex: 0 52%;
	max-width: 400px;
	height: 100%;
	padding-right: 10px;
	font-size: 14px;
	line-height: 30px;
}
.billing-modal-result-box {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 0;
}
.billing-modal-table-line {
	display: flex;
	width: 100%;
	background: #e1e1e1;
	position: relative;
	border-radius: 6px;
}
.billing-modal-table-line-active {
	display: flex;
	width: 30%;
	background: #5cbe7d;
	border-radius: 6px;
}
.billing-modal-table-line-box {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 10px;
}
.billing-graph-cont {
	padding: 0 40px;
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}
.billing-graph-box {
	width: 100%;
	min-height: 380px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	box-sizing: border-box;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	margin: 40px 20px 40px 0;
	padding: 20px;
}
.billing-large-text {
	font-size: 32px;
	line-height: 37px;
	text-transform: uppercase;
	font-weight: bold;
}
.billing-mid-text {
	font-size: 18px;
	line-height: 37px;
	text-transform: uppercase;
	font-weight: bold;
}
.billing-perf-total-cont {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.billing-ball {
	width: 8px;
	height: 8px;
	border-radius: 100%;
}
.billing-progress-cont {
	margin: 5px 0;
	overflow: auto;
	width: 100%;
	height: 170px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}
.billing-progress-box {
	display: flex;
	padding: 5px 20px;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.billing-width-progress {
	min-width: 120px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
.billing-table-line {
	display: flex;
	width: 100%;
	background: #c2e3d1;
	position: relative;
	border-radius: 3px;
}
.billing-table-line-active {
	display: flex;
	background: #5cbe7d;
	border-radius: 3px;
}
.billing-table-line-text {
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
}

/* Quantidade */
.billing-quan-box {
	display: flex;
	padding: 5px 20px;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}
.billing-quan-cont {
	display: flex;
	padding: 20px;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.billing-circular {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.billing-circular-box {
	width: 100%;
	display: flex;
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

@media screen and (max-width: 1300px) {
	.billing-graph-cont {
		flex-direction: column;
	}
	.billing-graph-box {
		padding: 10px;
		margin: 20px 0;
	}
	.billing-progress-box {
		padding: 5px;
	}
	.billing-width-progress {
		min-width: 60px;
	}
}
@media screen and (max-width: 769px) {
	.billing-modal-cont {
		width: 100%;
	}
}
@media screen and (max-width: 496px) {
	.billing-quan-box {
		flex-direction: column;
	}
}


.acompan-select-cont {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	display: flex;
	justify-content: center;
	flex-direction: row;
}
.acompan-select-title {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;
	color: #5A5A5A;	
}
.acompan-dates {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-around;
}
.acompan-labels {
	margin-bottom: 6px;
	color: rgba(0, 0, 0, 0.54);
	padding: 0;
	font-size: 14px;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1;
	letter-spacing: 0.00938em;
}
.acompan-calendar {
	display: flex;
	flex-direction: row;
	align-items: center;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	padding: 2px 15px;
	margin-right: 10px;
	background: #ffffff;
}
.acompan-calendar-icon {
	margin-left: 15px;
}
.acompan-calendar-input {
	color: rgba(0, 0, 0, 0.7);
	padding: 0;
	font-size: 16px;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1;
	letter-spacing: 0.00938em;
}
.acompan-calendar-input:hover {
	cursor: pointer;
	text-decoration: underline;
}
.acompan-select {
	width: 130px;
	height: 40px;
	background: #e2e2e2;
	border-radius: 5px;
	padding: 10px;
	margin: 5px 20px;
}
.acompan-status {
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 0 12%;
	width: 350px;
	padding-left: 10px;
}
.acompan-order {
	padding-right: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex: 0 18%;
	width: 200px;
	padding-left: 10px;
}
.acompan-channel {
	padding-right: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex: 0 14%;
	width: 180px;
	padding-left: 10px;
}
.acompan-date {
	padding-right: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex: 0 10%;
	width: 160px;
	padding-left: 10px;
}
.acompan-name {
	padding-right: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex: 0 22%;
	width: 250px;
	padding-left: 10px;
}
.acompan-country {
	padding-right: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 6%;
	width: 90px;
}
.acompan-amount {
	padding-right: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 12%;
	width: 180px;
}
.acompan-mail {
	padding-right: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 6%;
	width: 90px;
}
.acompan-status-closed {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #173e5e;
	padding-right: 5px;
}
.acompan-status-shipped {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #12ac9d;
	padding-right: 5px;
}
.acompan-status-accepted {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #ea596e;
	padding-right: 5px;
}
.acompan-order-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-decoration-line: underline;
	color: #5A5A5A;
	padding-right: 5px;
}
.acompan-channel-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #5a5a5a;
	padding-right: 5px;
}
.acompan-date-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #5a5a5a;
}
.acompan-name-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #5a5a5a;
	padding-right: 5px;
}
.acompan-amount-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #5a5a5a;
	padding-right: 5px;
}
@media screen and (max-width: 991px) {
	.acompan-dates {
		margin-bottom: 20px;
		margin-left: 20px;
	}
}
@media screen and (max-width: 769px) {
	.acompan-select-cont {
		flex-direction: column;
	}
	.acompan-select-title {
		padding-left: 0;
	}
}
.acompan-preloader div {
	min-height: auto;
	top: 43vh;
  }
  

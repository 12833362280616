.bi__wrapper {
	min-height: auto;
	overflow: hidden;
}
.bi__container {
	max-width: 1600px;
	margin-left: 280px;
	margin-right: auto;
}
.bi_main-title {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	line-height: 35px;
	text-align: center;
	color: #5a5a5a;
}
.bi_dates-box {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
}
.bi__title {
	padding-top: 80px;
}
.bi__title h2 {
	font-weight: 900;
	margin-bottom: 30px;
	font-size: 30px;
}
.bi__dates {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	background: #e2e2e2;
}
.bi__labels {
	margin-right: 6px;
	color: rgba(0, 0, 0, 0.54);
	padding: 0;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	letter-spacing: 0.00938em;
}
.bi__calendar {
	display: flex;
	flex-direction: row;
	align-items: center;
	background: #ffffff;
	border: 1px solid #5a5a5a;
	box-sizing: border-box;
	border-radius: 6px;
	padding: 2px 15px;
}
.bi__calendar_icon {
	margin-left: 15px;
	margin-top: 5px;
}
.bi__calendar_input {
	color: rgba(0, 0, 0, 0.7);
	padding: 0;
	font-size: 16px;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1;
	letter-spacing: 0.00938em;
}
.bi__calendar_input:hover {
	cursor: pointer;
	text-decoration: underline;
}
.bi__cards {
	display: flex;
	flex-direction: column;
	padding: 30px 0 0 20px;
	height: 88.4vh;
	background: #f2f2f2;
}
.bi__cards_group {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	flex-wrap: wrap;
	align-items: center;
	align-content: stretch;
}
.bi__cards_group_operations {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	align-content: stretch;
	margin: auto 0;
}
.bi__card_order_details {
	width: 1000px;
	height: 500px !important;
}
.bi__cards_title {
	font-weight: bold;
	font-size: 23px;
	margin-bottom: 15px;
	margin-left: 10px;
}
.bi__cards_title_B2C {
	margin-left: 10px;
}
.bi__cards_title_B2 span {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 25px;
	text-transform: uppercase;
	color: #5a5a5a;
}
.bi__cards_B2_header {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	justify-content: space-between;
	margin-right: 40px;
}
.bi__cards_B2_header_selects {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.coutry_select {
	padding: 10px;
	border-radius: 5px;
	width: 170px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	margin-left: 7px;
	background: #e5e5e5;
}
.main-select-bi {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	background: #e2e2e2;
	width: 150px;
	height: 40px;
	border: 1px solid #5a5a5a;
	box-sizing: border-box;
	border-radius: 6px;
	margin-right: 20px;
}
.bi__cards_marketplaces {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 30px;
	margin-left: 10px;
	justify-content: center;
	width: 100%;
	flex-wrap: wrap;
}
.bi__cards_marketplaces_cards {
	background: #e2e2e2;
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	padding: 10px;
	margin-right: 15px;
	margin-bottom: 20px;
	border: 1px solid #5a5a5a;
	box-sizing: border-box;
	border-radius: 6px;
}
.bi__cards_marketplaces_cards span {
	color: rgba(0, 0, 0, 0.6);
}
.bi__cards_marketplaces_cards:hover {
	cursor: pointer;
	transform: scale(1.05);
	background-color: rgb(200, 220, 240);
}
.modal-width {
	width: 800px !important;
	height: 600px !important;
}
.bi__card {
	width: 30%;
	height: 290px;
	padding: 5px;
	margin-bottom: 30px;
	background: #ffffff;
	box-shadow: 0px 10px 30px rgb(0 0 0 / 10%);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	margin-right: 20px;
}
.bi__card_operations {
	width: 30%;
	height: 450px;
	padding: 20px;
	margin-bottom: 30px;
	background: #ffffff;
	box-shadow: 0px 10px 30px rgb(0 0 0 / 10%);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	margin-right: 20px;
}
.bi__card_operations:hover {
	cursor: pointer;
	transform: scale(1.03);
}
.bi__card:hover {
	cursor: pointer;
	transform: scale(1.03);
}
.bi__card_title {
	font-size: 18px;
}
.bi__card_details {
	font-size: 10px;
	color: rgba(0, 0, 0, 0.54);
}
.bi__card_content {
	margin: auto;
	width: 100%;
	height: 200px;
}
.bi__card_content_operations {
	margin: auto;
	width: 350px;
	height: 380px;
}
.bi__card_content div {
	font-size: 70px;
	font-weight: lighter;
	margin-top: 40px;
}
.bi__card_content_avg {
	margin: auto;
	width: 250px;
	font-size: 45px;
	font-weight: lighter;
}
.bi__card_content_avg_currency {
	font-size: 25px;
}
.bi__graph * {
	z-index: -1000 !important;
}
.line {
	border-top: 2px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 20px;
	margin-right: 40px;
}
.MuiDataGrid-columnHeaderTitle {
	margin-right: 20px;
}
.dateB__container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	flex-wrap: wrap;
	padding-left: 40px;
	background: #e2e2e2;
}
.dateB__label {
	text-transform: uppercase;
	margin-right: 20px;
}
.dateB__button {
	border: 1px solid #5a5a5a;
	box-sizing: border-box;
	border-radius: 6px;
	padding: 10px;
	margin-right: 15px;
}
.dateB__button:hover {
	cursor: pointer;
	transform: scale(1.05);
	background-color: rgb(200, 220, 240);
}
.dateB__button span {
	color: rgba(0, 0, 0, 0.6);
}
.activeBtn {
	background-color: rgb(203, 200, 240);
}
.bi-header-cont {
	display: flex;
	flex-direction: row;
	width: 100%;
	background: #e2e2e2;
	padding: 20px 40px;
	align-items: center;
	justify-content: space-between;
	z-index: 8;
	filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
}
.bi-filters-cont {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}
/* MODAL-FILTER-START */
.filter-container {
	padding: 5px;
	max-width: 360px;
	/* max-height: 460px; */
	display: flex;
	flex-direction: column;
	height: 460px;
}
.filter-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	width: 100%;
}
.filter-header-title {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 16px;
	color: #000;
}
.filter-header-reset {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.7);
	text-decoration-line: underline;
}
.filter-header-reset:hover {
	cursor: pointer;
}
.filter-line {
	border-top: 2px solid rgba(0, 0, 0, 0.1);
	margin: 10px 20px 20px 20px;
}
.filter-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
	/* flex: 1 0 auto; */
}
.filter-datepicker {
	margin-bottom: 10px;
}
.filter-dates {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}
.filter-dates-box {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 0px 10px;
}
.filter-dates-label {
	margin-right: 6px;
	color: rgba(0, 0, 0, 0.54);
	padding: 0;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	letter-spacing: 0.00938em;
	margin-bottom: 7px;
}
.filter-dates-calendar {
	display: flex;
	flex-direction: row;
	align-items: center;
	background: #ffffff;
	border: 1px solid #5a5a5a;
	box-sizing: border-box;
	border-radius: 6px;
	padding: 2px 5px;
}
.filter-calendar-icon {
	margin-left: 5px;
	margin-top: 5px;
}
.filter-selects-cont {
	margin: 15px 0px;
}
.filter-select {
	padding: 10px;
	border-radius: 5px;
	width: 280px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5a5a5a;
	background: #e5e5e5;
	margin-bottom: 15px;
}
.filter-buttons {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: auto;
	/* margin-bottom: 5px; */
	/* flex: 0 0 auto; */
}
.filter-apply-btn {
	display: flex;
	width: 150px;
	height: 40px;
	justify-content: center;
	align-items: center;
	background: #af1b3f;
	border-radius: 10px;
	box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;
	color: #ffffff;
	margin-bottom: 10px;
}
.filter-cancel-btn {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	text-decoration-line: underline;
	text-transform: uppercase;
	color: #af1b3f;
	background-color: #fff;
	border: none;
	box-shadow: none;
}
/* MODAL-FILTER-END */

/* @media screen and (max-width: 1440px) {
	.bi__cards_group {
		justify-content: space-between;
	}

} */
@media screen and (max-width: 1300px) {
	.bi__cards_title {
		text-align: center;
	}
	.bi__cards_title_B2C {
		text-align: center;
	}
	.bi__card_order_details {
		width: 730px;
	}
}
@media screen and (max-width: 991px) {
	.bi__cards {
		height: 100%;
	}
	.modal-width {
		width: 700px !important;
		height: 500px !important;
		overflow: overlay;
	}
	.bi-header-cont {
		flex-direction: column-reverse;
	}
	.dateB__container {
		flex-direction: column;
	}
	.bi-filters-cont {
		flex-direction: column;
	}
	.dateB__label {
		margin-right: 0;
	}
	.main-select-bi {
		margin-right: 0;
	}
	.dateB__container {
		padding-left: 0;
		padding-bottom: 20px;
	}
	.table-title-sort {
		padding-bottom: 20px;
	}
	.bi__card {
		width: 45%;
		height: 400px;
	}
	.bi__cards_group {
		justify-content: center;
	}
}
@media screen and (max-width: 769px) {
	.bi__card {
		width: 100%;
	}
	.bi__cards_B2_header_selects {
		flex-direction: column;
	}
	.coutry_select {
		margin-bottom: 5px;
	}
	.bi__dates {
		flex-direction: column;
	}
	.bi_dates-box {
		margin: 10px 0;
	}
	.modal-width {
		width: 420px !important;
		height: 300px !important;
	}
	.bi__card_order_details {
		width: 420px;
	}
}
@media screen and (max-width: 469px) {
	.bi__dates {
		flex-direction: column;
	}
	.bi-date-cont {
		flex-direction: column;
	}
	.bi_date {
		margin-bottom: 20px;
	}
	.modal-width {
		width: 280px !important;
		height: 200px !important;
	}
	.bi__card_order_details {
		width: 280px;
	}
}

.main-nav {
  width: 240px;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 10 !important;  
}
  .navigation-cont {
    background: #920023;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    color: #FFFFFF;
    height: 100%;
    width: 100%;
    padding-top: 60px;
  }
  .nav-button {
    position: absolute;
    top: 15px;
    left: 0;
  }
  .arrow-nav {
    position: absolute;
    top: 20px;
    left: 225px;
  }
  .navigation-menu-cont {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .navigation-menu-box {
    display: flex;
    width: 98%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #C1B190;
    padding: 15px 0;
    border-left: 5px solid #920023;
  }
  .navigation-menu-box:active {
    background-color:#920023 !important;
    border-left: 5px solid #FFFFFF;
  }
  .navigation-menu-box-active {
    background-color:#920023 !important;
    border-left: 5px solid #FFFFFF;
  }
  .navigation-menu-box-active-underline {
    background-color:#920023 !important;
    text-decoration: underline;
  }
  .navigation-menu-box:focus {
    border-left: 5px solid #FFFFFF;
  }
  .border-box {
    margin-top: 20px;
    border-top: 1px solid #C1B190;
    width: 98%;
  }
  .navigation-menu-box-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .navigation-image {
    padding-left: 15px;
    padding-right: 15px;
    min-width: 50px;
  }
  .billing-image {
    margin: 0 4px;
  }
  .navigation-image-settings {
    min-width: 30px;
  }
  .navigation-menu-box-settings {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #C1B190;
    padding: 10px 0;
    border-left: 5px solid #920023;
    background: #920023;
  }
  .navigation-menu-box-settings:active {
    background-color:#920023 !important;
    border-left: 5px solid #FFFFFF;
  }
  .navigation-menu-box-settings:focus {
    border-left: 5px solid #FFFFFF;
  }
  .navigation-menu-box-text-settings {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    margin-left: 15px;
    border: none;
    display: flex;
    align-items: center;
  }
  .navigation-image-setting {
    padding-right: 15px;
  }
  @media screen and (max-width: 991px) {
    .main-nav {
        display: inline;
        margin-left: 0;
    }
  }
  .bg {
    background: #920023 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    box-shadow: none;
  }
  .nav-settings-cont {
    display: flex;
    flex-direction: column;
    background: #920023 !important;
    color: #ffffff !important;
    box-shadow: none;
  }
  .nav-settings-box {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    margin: 10px 0;
    box-shadow: none;
  }
  .accordion-nav {
    width: 98%;
    box-shadow: none;
    box-shadow: none !important;
    margin: 0 !important;
  }
  .icon-nav-accordion {
    color: #FFFFFF !important;
    box-shadow: none;
  }
  
.s_main-login {
	position: static;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: #920023;
    flex-direction: column;
}
.login-box {
	width: 420px;
	border-radius: 10px;
	background: #FFFFFF;
	box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
	border-radius: 6px;
	font-family: K2D, sans-serif;
	padding: 30px 15px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.in-column {
	display: flex;
	justify-content: center;
	align-items: center;
}
.email_text_box {
	display: flex;
	align-items: center;
	text-align: left;
	margin-bottom: 10px;
	justify-content: space-between;
}
.email-text-login {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #5A5A5A;
}
.password-text-login {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #5A5A5A;
}
.email-field {
	padding: 10px;
	width: 100%;
}
.password-field {
	padding: 10px;
	width: 100%;
}
.log-in-login {
	background: #AF1B3F;
	border-radius: 6px;
	width: 100%;
	padding: 12px 0;
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #FFFFFF;
}
.button_box {
	display: flex;
	margin-top: 20px;
	text-align: center;
}
.esqueci-text {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: right;
	color: #AF1B3F;
}
.esqueci-text a {
	cursor: pointer;
}
.esqueci_text_box {
	width: 320px;
	color: #5A5A5A;
}
.esqueci_text_box h3 {
	margin-bottom: 15px;    
    text-transform: uppercase;
}
.esqueci_text_box p {
	margin-bottom: 10px;
}
.login-input {
	width: 320px;
    display: flex;
    height: 42px;
    padding: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #C1C1C1;
	box-sizing: border-box;
	border-radius: 20px;
    background: #FFFFFF;
	background: #F4F4F4;
	border: 1px solid #DADADA;
	box-sizing: border-box;
	border-radius: 6px;
	margin-bottom: 30px;
}
.lembre-me-box {
	width: 120px;
}
.lembre-me {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #5A5A5A;
}
.login-text-box {
	display: flex;
	align-items: center;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 10px;
	justify-content: space-between;
}
.lingua-transform {
	transform: translate(42px, 2px) scale(0.75) !important;
}
.contato {
	width: 450px;
	margin: 20px 0 10px;
	display: flex;
	justify-content: space-between;
	color: #fff;
	font-size: 14px;
}

.contato a:hover {
	text-decoration: underline;
}

.wait {
	opacity: 0.5;
	cursor: wait;
}

@media screen and (max-width: 767px) {
	.login-form-block {
		margin-bottom: 15px;
	}
}
@media screen and (max-width: 479px) {
	.s_main-login {
		padding-top: 20px;
	}
	.login-box {
		width: 100%;
	}
	.login-input {
		width: 280px;
	}
	.login-box {
		width: 100%;
		padding: 30px 0;
	}
	.contato {
		width: 96%;
	}
}
